import fetchWrapper from '../utils/FetchWrapper'

const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN

export function getAccessToken () {
  return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/getAccessToken`)
}

export function getAccessTokenByOTC(authorizationCode) {
  return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/oauth/token?code=${authorizationCode}`)
}

export function logout() {
  return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/logoutUser`)
}

export function getAccountExperience() {
  //temporarily uncomment one of following lines to return a fake feature flag for testing
  //return new Promise(resolve => resolve({"message":"Success","data":{"experience":"v2","domain":"accounts-qa.netgear.com"}}))
  //return new Promise(resolve => resolve({"message":"Success","data":{"experience":"v1","domain":"https://accounts-stg.netgear.com"}}))
  return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/accounts/experience`)
}